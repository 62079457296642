<template>
  <section>
    <loader/>
    <router-view></router-view>
  </section>
</template>


</style>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.rtl {
  direction: rtl;
}
</style>


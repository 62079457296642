<template>
  <div class="sidebar">
    <div class="sidebar-inner" style="border-right: 1px #ddd solid">
      <!-- ### $Sidebar Header ### -->
      <div class="sidebar-logo" style="border-bottom: white">
        <div class="peers ai-c fxw-nw" style="padding: 20 20px">
          <div class="peer peer-greed">
            <a
              class="sidebar-link td-n"
              href="#"
              ><div class="peers ai-c fxw-nw">
                <div class="peer">
                  <div class="logo">
                    <img src="@/assets/static/images/logo/logo.svg" style="margin:10px;" alt="" height="50"/>
                  </div>
                </div>
                <div class="peer peer-greed">
                  <h5 class="lh-1 mB-0 logo-text">{{$t('appName')}}</h5>
                </div>
              </div></a
            >
          </div>
          <div class="peer">
            <div class="mobile-toggle sidebar-toggle">
              <a href="" class="td-n">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- ### $Sidebar Menu ### -->
      <ul class="sidebar-menu scrollable pos-r">
        <div class="mT-30"></div>
        <li v-if="getRole() == 'owner'" id="li1" class="nav-item dropdown">
          <a class="dropdown-toggle" href="javascript:void(0);" v-on:click="fn('1')">
            <span class="icon-holder"><i class="c-blue-700  ti-pie-chart"></i> </span>
            <span class="title">{{$t('reports') }}</span> 
            <span class="arrow"><i class="ti-angle-right"></i></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="sidebar-link" to="/incomeReport">{{$t('incomeReport') }}</router-link></li>
              <!-- <li><router-link class="sidebar-link" to="/ordersReprot">{{$t('ordersReport') }}</router-link></li> -->
            </ul>
          </li>
        <li v-if="getRole() == 'owner'" class="nav-item" >
          <router-link class="sidebar-link"  to="/branches">
            <span class="icon-holder">
              <i class="c-blue-700 ti-home"></i>
            </span>
            <span class="title">{{ $t('branches') }}</span>
          </router-link>
        </li>
        <li v-if="getRole() != 'owner'" id="li1" class="nav-item dropdown">
          <a class="dropdown-toggle" href="javascript:void(0);" v-on:click="fn('1')">
            <span class="icon-holder"><i class="c-blue-700  ti-gift"></i> </span>
            <span class="title">{{$t('orders') }}</span> 
            <span class="arrow"><i class="ti-angle-right"></i></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="sidebar-link" to="/addOrder">{{$t('addOrder') }}</router-link></li>
              <li><router-link class="sidebar-link" to="/orders">{{$t('showOrders') }}</router-link></li>
            </ul>
          </li>
          <li v-if="getRole() != 'data_entery'" id="li2" class="nav-item dropdown">
          <a class="dropdown-toggle" href="javascript:void(0);" v-on:click="fn('2')">
            <span class="icon-holder"><i class="c-blue-700 ti-user"></i> </span>
            <span class="title">{{$t('employees') }}</span> 
            <span class="arrow"><i class="ti-angle-right"></i></span></a>
            <ul class="dropdown-menu">
              <li><router-link class="sidebar-link" to="/attendances">{{$t('attendances') }}</router-link></li>
              <li><router-link class="sidebar-link" to="/employees">{{$t('showEmployees') }}</router-link></li>
            </ul>
          </li>
        <li v-if="getRole() != 'data_entery'" class="nav-item">
          <router-link class="sidebar-link" to="/costs">
            <span class="icon-holder">
              <i class="c-blue-700 ti-stats-up"></i>
            </span>
            <span class="title">{{$t('costs')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link"  to="/clients">
            <span class="icon-holder">
              <i class="c-blue-700 ti-agenda"></i>
            </span>
            <span class="title">{{ $t('clients') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods : {
    getRole() {
      return sessionStorage.getItem('role');
    },
    fn: function(id)  {
      var stringId = '#li'+id;
    if($( stringId).attr('class') == 'nav-item dropdown open') {
      $( stringId ).removeClass('open');
    } 
    else {
      $( stringId ).addClass('open');
    }
  }
  }
};
</script>

<style>
</style>
<template>
  <div
    class="header navbar"
    style="border-color: #ddd; border-width: 0 0 1px 0"
  >
    <div class="header-container">
      <ul class="nav-left">
        <li>
          <a
            id="sidebar-toggle"
            class="sidebar-toggle"
            href="javascript:void(0)"
            v-on:click="fn"
            ><i class="ti-menu"></i
          ></a>
        </li>
      </ul>
      <ul class="nav-right">
        <li style="padding-top:13px">
          <lang/>
        </li>
        <li class="dropdown">
          <a
            href=""
            class="dropdown-toggle no-after peers fxw-nw ai-c lh-1"
            data-toggle="dropdown"
          >
            <div class="peer mR-10">
              <img
                class="w-2r bdrs-50p"
                src="@/assets/static/images/user.png"
                alt=""
              />
            </div>
            <div class="peer">
              <span class="fsz-sm c-grey-900">{{ getName() }}</span>
            </div>
          </a>
          <ul class="dropdown-menu fsz-sm">
            <li role="separator" class="divider"></li>
            <li>
              <a class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                <i class="ti-power-off mR-10"></i>
                <span @click="logout()">{{ $t('logout') }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Lang from './Lang.vue';
export default {
  methods: {
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.go();
    },
    fn: function()  {
    if($( "body" ).attr('class') == 'app is-collapsed') {
      $( "body" ).removeClass('is-collapsed');
    } 
    else {
      $( "body" ).addClass('is-collapsed');
    }
  },
  getName() {
    return sessionStorage.getItem('name');
  }
  },
};
</script>

<style>
</style>
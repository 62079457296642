import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../components/Redirect.vue')
  },
  {
    path: '/',
    component: () => import('../views/home/Home.vue'),
    children: [
      {
        path: 'incomeReport',
        name: 'incomeReport',
        component: () => import('../views/dashboard/IncomeReport.vue'),
      },
      {
        path: 'employees',
        name: 'employees',
        component: () => import('../views/employees/Employees.vue'),
      },
      {
        path: 'attendances',
        name: 'attendances',
        component: () => import('../views/employees/Attendances.vue'),
      },
      {
        path: 'clients',
        name: 'clients',
        component: () => import('../views/clients/Clients.vue'),
      },
      {
        path: 'costs',
        name: 'costs',
        component: () => import('../views/costs/Costs.vue'),
      },
      {
        path: 'branches',
        name: 'branches',
        component: () => import('../views/branches/Branches.vue'),
      },
      {
        path: 'addOrder',
        name: 'addOrder',
        component: () => import('../views/orders/AddOrder.vue'),
      },
      {
        path: 'orderItems',
        name: 'orderItems',
        props: (route) => ({
          id: route.query.id,
        }),
        component: () => import('../views/orders/OrderItems.vue'),
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../views/orders/Orders.vue'),
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../components/404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../components/500.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== '404' && to.name !== '500') {
    if(sessionStorage.getItem('token') == null) {
      console.log('inside 1 if');
      if(localStorage.getItem('token') == null) {
        console.log('inside 2 if');
        if(to.name == 'login') next()
        else next({ name: 'login' })
      }
      else {
        console.log('inside 1 else');
        sessionStorage.setItem('token' ,localStorage.getItem('token'));
        sessionStorage.setItem('name' ,localStorage.getItem('name'));
        sessionStorage.setItem('role' ,localStorage.getItem('role'));
        next({ name: 'login' })
      }
    }
      else if (to.name === "redirect" | to.name === "login" | to.name === "/") {
        switch(sessionStorage.getItem('role')) {
          case 'owner' : next({ name: 'incomeReport' });
          break;
          case 'supervisor' : next({name : 'orders'})
          break;
          default : next({name : 'addOrder'})
        }
      }
      else next()
    }
    else next()
  })


export default router
